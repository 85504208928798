<template>
  <div>
    <a-card :bordered="false" class="card-product header-solid mb-24">
      <template #title>
        <h6>代理IP设置</h6>
      </template>
<!--      <a-row type="flex" :gutter="[24]">-->
<!--        <a-col :span="24" class="col-gallery">-->
<!--          <img class="gallery-img shadow-3 img-contain" src="images/banner.png" alt="banner">-->
<!--        </a-col>-->
<!--      </a-row>-->
      <div>
        <h6>我的IP</h6>
        <a-table :columns="columns" :data-source="ipData" :pagination="false">
          <template slot="countdown" slot-scope="row">
            {{ row }}天
            <a-tag v-if="ip_status === 1" class="tag-status ant-tag-green" style="margin-left: 3px">
              正常
            </a-tag>
            <a-tag v-if="ip_status === 2" class="tag-status ant-tag-yellow" style="margin-left: 3px">
              即将到期
            </a-tag>
            <a-tag v-if="ip_status === 3" class="tag-status ant-tag-red" style="margin-left: 3px">
              即将自动终止跟单
            </a-tag>
          </template>
          <template slot="renewBtn" slot-scope="row">
            <!--<a-button type="link" :data-id="row.key" class="btn-edit" @click="renewBtn(row.key)">-->
            <a-button type="link" class="btn-edit" style="color: #4190f7;">
              <!--{{ row.status === 1 ? "终止跟单" : "" }}-->更换
            </a-button>
          </template>
        </a-table>
      </div>
      <div style="margin-top: 30px">
        <h6>IP白名单设置</h6>
        <p>OKX设置参考：<a target="_blank" href="https://txc.qq.com/products/647309/faqs-more/?id=154642">申请欧意（okx）实盘API</a></p>
        <a-alert
            v-if="ip_status != 0"
            message="成功分配交易服务器IP"
            type="success"
            show-icon
        >
          <template v-slot:description>
            请将 <span style="color: rgb(236, 87, 91)">{{ipData[0].ip}}</span> 添加到交易所API的IP白名单中，开启极速交易！
          </template>
        </a-alert>
        <a-alert
            v-else-if="ip_status === 0"
            message="没有可用的代理IP"
            type="info"
            show-icon
        >
          <template v-slot:description>
            如需开启实盘交易，请购买代理IP或者VIP会员。
          </template>
        </a-alert>
      </div>
      <div style="margin: 50px 0">
        <h6>购买/续费代理IP服务</h6>
        <div style="width: 800px">
          <a-row class="buy">
            <a-col :span="12" class="buy-title">
              购买时长
            </a-col>
            <a-col :span="12" class="buy-content">
              <a-row class="checkbox-group-time" type="flex" justify="start" :gutter="[16]">
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[countdown === '30天' ? 'active' : '']"
                       @click="toggleCheckbox_time('30天')"
                  >
                    <h6>30天</h6>
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[countdown === '60天' ? 'active' : '']"
                       @click="toggleCheckbox_time('60天')"
                  >
                    <h6>60天</h6>
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[countdown === '90天' ? 'active' : '']"
                       @click="toggleCheckbox_time('90天')"
                  >
                    <h6>90天</h6>
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[countdown === '180天' ? 'active' : '']"
                       @click="toggleCheckbox_time('180天')"
                  >
                    <h6>180天</h6>
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[countdown === '360天' ? 'active' : '']"
                       @click="toggleCheckbox_time('360天')"
                  >
                    <h6>360天</h6>
                  </div>
                </a-col>
              </a-row>
              <a-radio-group class="d-none" v-model="countdown" :options="countdownOptions"/>
            </a-col>
          </a-row>
          <a-row class="buy">
            <a-col :span="12" class="buy-title">
              单价
            </a-col>
            <a-col :span="12" class="buy-content">
              <span style="font-size: 18px;color: #EC575BFF;font-weight: 600;">$ {{ price }} / {{countdown}}</span>
            </a-col>
          </a-row>
          <a-row class="buy">
            <a-col :span="12" class="buy-title">
              支付方式
            </a-col>
            <a-col :span="12" class="buy-content">
              <a-row class="checkbox-group-payment" type="flex" justify="start" :gutter="[16]">
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[payment === 'trc20' ? 'active' : '']"
                       @click="toggleCheckbox_payment('trc20')"
                  >
                    <img src="images/trc.png" alt="trc20">
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[payment === 'alipay' ? 'active' : '']"
                       @click="toggleCheckbox_payment('alipay')"
                  >
                    <img src="images/alipay.png" alt="alipay">
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="checkbox-label"
                       :class="[payment === 'wechatpay' ? 'active' : '']"
                       @click="toggleCheckbox_payment('wechatpay')"
                  >
                    <img src="images/wechatpay.png" alt="wechatpay">
                  </div>
                </a-col>
              </a-row>
              <a-radio-group class="d-none" v-model="payment" :options="paymentOptions"/>
            </a-col>
          </a-row>
          <a-row class="buy">
            <a-col :span="12" class="buy-title">
            </a-col>
            <a-col :span="12" class="buy-content">
              <a-button disabled type="primary" style="width: 150px"><a-icon type="shopping" theme="outlined" />支 付 ${{price}}</a-button>
            </a-col>
          </a-row>

        </div>
      </div>
    </a-card>
    <div class="dashboard">
      <a-row :gutter="24" type="flex" align="stretch" style="width: 50%">
        <a-row :gutter="24" style="width: 100%;left: 10px">
          <a-col :span="24" :lg="24" class="mb-24" style="position: relative; ">

            <!-- 充值 Card -->
            <CardCharge :fetchData="fetchData"></CardCharge>
            <!-- / 充值 Card -->

          </a-col>
        </a-row>
      </a-row>
    </div>
  </div>
</template>

<script>
import CardCharge from '../components/Cards/CardCharge' ;
import http from "../utils/http"

const columns = [
  {
    title: 'IP',
    dataIndex: 'ip',
    scopedSlots: {customRender: 'ip'},
  },
  {
    title: '到期时间',
    dataIndex: 'countdown',
    scopedSlots: {customRender: 'countdown'},
  },
  {
    title: '',
    dataIndex: 'renew',
    scopedSlots: {customRender: 'renewBtn'},
  },
]

export default {
  name: "Proxy",
  components: {
    CardCharge,
  },
  data() {
    return {
      price: 15,
      // 选择支付方式
      payment: 'trc20',
      // Checkbox'es array of all options.
      paymentOptions: ['trc20', 'alipay', 'wechatpay'],

      // 选择时间
      countdown: '30天',
      // 选择时间
      countdownOptions: ['30天','60天','90天','180天','360天'],

      ipData: [],
      ip_status: 0, // 0:无ip 1:正常 2/3:即将到期
      columns,

      is_ip: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await http.get('/api/ip/?token=' + localStorage.getItem("token"));
        if (response.data.data && response.data.data.length > 0) {
          this.ipData = [
              {
                ip: response.data.data[0].ip,
                countdown: response.data.data[0].countdown,
              },
          ];
          this.tips(response.data.data[0].countdown)
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
      }
    },
    tips(e) {
      const countdown = e;
      if (countdown > 7) {
        this.ip_status = 1;
      } else if (countdown <= 7 && countdown > 2) {
        this.ip_status = 2;
      } else if (countdown <= 2) {
        this.ip_status = 3;
      }
    },
    toggleCheckbox_time(item) {
      this.countdown = item;
      this.price = parseInt(item.split('天')[0], 10)/2;
    },
    toggleCheckbox_payment(item) {
      this.payment = item;
    },
    toplatform(selectedOption) {
      if (selectedOption === '欧意OKX') {
        return '1';
      } else if (selectedOption === '币安Binance') {
        return '2';
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '../scss/base/_variables';

.checkbox-group-time {
  .checkbox-label {
    cursor: pointer;
    padding-bottom: 3px;
    text-align: center;
    border: 1px solid #d9d9d9;
    font-size: .875rem;
    border-radius: 4px;


    &.active {
      background-color: $color-primary;

      .fill-dark {
        fill: $color-white;
      }
    }
  }
  h6 {
    font-size: 14px!important;
    font-weight: $fw-semibold;
    text-align: center;
    margin: 5px;
  }
}
.checkbox-group-payment {

  .checkbox-label {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */

    cursor: pointer;
    padding-bottom: 3px;
    text-align: center;
    border: 1px solid #d9d9d9;
    font-size: .875rem;
    border-radius: 4px;
    height: 41px;


    &.active {
      //background-color: $color-primary;
      border:1.5px solid  #1890FFFF;

      .fill-dark {
        fill: $color-white;
      }
    }
  }
  img {
    height: 26px;
  }
  h6 {
    font-size: 14px!important;
    font-weight: $fw-semibold;
    text-align: center;
    margin: 5px;
  }
}
.checkbox {
  border-radius: 4px;
  h6{
    font-size: 14px!important;
    margin: 8px!important;
  }
}

.buy {
  display: flex;
  align-items: center;  /* 垂直居中 */
  margin-top: 25px;
  font-size: 15px;
  font-weight: 500;
  .buy-title {
    width: 140px;
    text-align: right;  /* 文本靠右对齐 */
  }
  .buy-content {
    margin-left: 20px;
    flex: 1;  /* 占据剩余空间 */
    text-align: left;  /* 文本靠左对齐 */
  }
}

.col-gallery {
  .gallery-img {
    border-radius: 12px;
  }

  .pswipe-gallery {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    img {
      height: 100px;
      width: 100px;
      border-radius: 12px;
      //box-shadow: $shadow-3;
    }
  }
}
.img-contain {
  max-width: 100%;
}
</style>